import styled from '@emotion/styled'
import { CheckCircle, Gift } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  benefits: string[]
  title?: string
}

export const Benefits = memo(function Benefits({ benefits, title }: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <List inline row wrap>
        {benefits.map((item: any, index) => (
          <Item dial={4} key={index} row>
            <CheckCircle />
            <Label>{item.label}</Label>
          </Item>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 3.75rem 3.333vw;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 3.25rem 1.875rem 1.875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
`

const List = styled(FlexBox)`
  margin: auto;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    margin-top: 0.75rem;
  }
`

const Item = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.secondaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;
  margin-top: 2.625rem;
  padding: 0 1.875rem;

  svg {
    width: auto;
    height: 1.875rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight3};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.3;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.75rem;
    padding: 0 1.875rem;
    text-align: center;

    svg {
      display: none;
    }
  }
`

const Label = styled.span`
  width: calc(100% - 2.875rem);
  margin-left: 1rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
`
