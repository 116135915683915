import styled from '@emotion/styled'
import { Section } from 'app/components/Common/Section'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  languageCode: string
}

export const BannerCTA = memo(function BannerCTA({
  items,
  languageCode,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Section languageCode={languageCode} />
      <Wrapper dial={5} row stretch wrap>
        {items.slice(0, 2).map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 16.25rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 12.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 10rem;
    padding: 1.25rem 0;
  }
`

const Wrapper = styled(FlexBox)``
