import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  offers: OfferProps[]
}

export const OffersList = memo(function OffersList({ offers }: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Offers row stretch wrap>
        {offers.map((item, index) => (
          <Offer key={index} {...item} />
        ))}
      </Offers>
    </Container>
  )
})

const Container = styled.section`
  margin: 5rem auto 8.125rem;
  padding: 0 6.667vw;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3rem auto 5rem;
    padding: 0 1.875rem;
  }
`

const Offers = styled(FlexBox)`
  margin-right: -5.556vw;

  @media (max-width: 1023px) {
    margin-right: -1.875rem;
  }
`
